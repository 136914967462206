import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import BackgroundImg from "../images/video/index.mp4"
import {Container, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap'



const IndexHeader = ({ siteTitle }) => (


    <header
        className="mainhead"
        style={{ height: `100vh` }}
    >
        <div className="video-overlay">
            <video autoPlay loop>
                <source src={BackgroundImg} type="video/mp4" />
            </video>
        </div>

        <Container>
            <div className="intro-text">
                <div className="intro-heading text-uppercase">Outsourcing company</div>
                <div className="intro-lead-in">Specialized for customer support, providing high-quality Call Center services and IT outsourcing solutions</div>

                {/*<a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a>*/}
            </div>
        </Container>


    </header>
)

IndexHeader.propTypes = {
    siteTitle: PropTypes.string,
}

IndexHeader.defaultProps = {
    siteTitle: ``,
}

export default IndexHeader