import React from "react"
import { Link } from "gatsby"
import { Container } from 'react-bootstrap'

import IndexHeader from "../components/index-header"
import ImgCallCenter from "../images/pages/call-center.jpg"
import ImgItSolutions from "../images/pages/it-solutions.jpg"
import ImgBackOffice from "../images/pages/back-office.jpg"
import ImgCogs from "../images/misc/cogs.jpg"
import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <IndexHeader  />

        <SEO title="Home" />

        <section className="page-section">
            <Container>

                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">About us</h2>
                        <h3 className="section-subheading text-muted">We make the difference.</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10">


                        <p className="text-muted text-justify text-large mb-0">
                            We make the difference in the Contact Center marketplace through proven customer management solutions.
                            Smart Solution LLC specializes in providing flexible, high quality customer support outsourcing solutions with an emphasis on inbound and outbound communication channels encompassing phone, live chat and e-mail.
                            We also provide a full range of IT services to support your business infrastructure outside of your data center, too.
                            Since we are located in Kosovo, our aim is to offer an outsource services with a reduced labour cost to our partners and keep the same quality of services.</p>


                    </div>

                </div>


            </Container>
        </section>

        <section className="bg-light page-section">
            <Container>

                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">Services</h2>
                        <h3 className="section-subheading text-muted">We provide a variety of services...</h3>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-md-4 my-3 move-up">
                        <Link to="/services/call-center">
                            <div className="service-item h-100">
                                <img src={ImgCallCenter} className="img-fluid"/>
                                <div className="service-caption">
                                    <h4 className="service-heading text-dark">Call Center</h4>
                                    <p className="text-muted mb-0">We provide a full service call center solution for your business with our agents handling all points of contact. We work closely with you to ensure that your business culture and needs are fully represented in all areas during the onboarding process and setup.</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-4 my-3 move-up">
                        <Link to="/services/it-solutions">
                        <div className="service-item h-100">
                            <img src={ImgItSolutions} className="img-fluid"/>
                            <div className="service-caption">
                                <h4 className="service-heading text-dark">IT Solutions</h4>
                                <p className="text-muted mb-0">We offer a full range of IT services to support your business infrastructure outside of your data center, too. From networking to hardware to applications, our team of certified experts will monitor, manage and maintain your IT environment.</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4 my-3 move-up">
                        <Link to="/services/back-office">
                        <div className="service-item h-100">
                            <img src={ImgBackOffice} className="img-fluid"/>
                            <div className="service-caption">
                                <h4 className="service-heading text-dark">Back-office</h4>
                                <p className="text-muted mb-0">Back office outsourcing provides a vital link to businesses to provide them with more technical and robust manpower that will enable small businesses to immediately scale its operations without additional capital expenditures</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>


            </Container>
        </section>





        <div style={{
            backgroundImage: `url(`+ImgCogs+`)`,
            backgroundSize: `cover`
        }}>
            <section className="page-section"
                     style={{
                         backgroundColor: `rgba(0,0,0,0.7)`
                     }}
            >
                <Container>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading text-light">24/7/365</h2>
                            <h3 className="section-subheading"  style={{ color: `#f5990f`}}>
                                Available at any time, every day of the year
                            </h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-10">
                            <p className="display-5 text-justify mb-0 text-light text-large">
                                Thanks to technology life and business often deviate from the traditional business hours, so can your support. We operate 24/7/365 at no additional cost in order to meet our  based clients international support needs.
                            </p>
                        </div>
                    </div>
                </Container>
            </section>
        </div>

        <section className="page-section">
            <Container >
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">Transparency in Support</h2>
                        <h3 className="section-subheading text-muted">We believe that transparency creates trust.</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10">
                        <p className="text-muted text-justify text-large mb-0">
                            Smart Solution Call Center changed the way our agents interact with our clients information and data for the better. Using The Community we are able to provide relevant account specific information on a need to know basis using fully searchable and easily updated Community pages. This informational search ability gives us a lot of flex in being able to train agents to free script, making calls flow as naturally as possible while allowing agents to capture information correctly, cross reference other areas without jumping script and to provide a first call resolution as often as possible. It allows our clients to listen to calls and pull call logs with metrics on demand.</p>
                    </div>
                </div>
            </Container>
        </section>

        <section className="page-section bg-light"
                 style={{borderBottom: `10px solid #ccc;`}}
        >
            <Container >
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-heading">We are hiring</h2>
                        <h3 className="section-subheading text-muted">Get on board and make a difference!</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10">
                        <p className="text-muted text-justify text-large ">
                            If you’re interested in Customer Care and enjoy talking to customers over the phone and via email, we’d like to meet you! We offer a vibrant workplace with a great team, as well as a balanced working life.</p><p className="text-muted text-large mb-0"> If you're interested, take a look at our <Link to="/careers">job vacancies</Link> on Career page</p>
                    </div>
                </div>
            </Container>
        </section>


    </Layout>
)

export default IndexPage
